import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/register',
    component: () => import('@/pages/register/index')
  },
  {
    path: '/newsDetail/:newsId',
    name:'NewsDetail',
    component: () => import('@/views/News/detail'),
    meta:{ title: '资讯详情',auth:false}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
