<template>
    <div class="aside-container" :class="classObj">
        <div class="aside-box">
            <div class="header acea-row row-middle row-between">
                <div class="avatar-box acea-row row-middle">
                    <img v-if="user" :src="user && user.avatar ? user.avatar : defaultAvatar" alt="">
                    <img v-else src="@/assets/avatar.png" alt="">
                    <div v-show="!user" class="login" @click="toMiniProgram">一键登录</div>
                </div>
                <div class="register" @click="toRegister" v-show="!user">立即注册</div>
            </div>
            <div class="open-btn" @click="toMiniProgram">打开南安跨境服务平台小程序</div>
            <div class="menu-list" @click="toMiniProgram">
                <div class="menu-item" v-for="item in menuList" :key="item.id">
                    {{ item.label }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { getSchemeUrl } from "@/api/index"
    export default {
        data() {
            return {
                menuList:[{
                    id:1,
                    label:'首页'
                },{
                    id:2,
                    label:'综合服务'
                },{
                    id:3,
                    label:'选品中心'
                },{
                    id:4,
                    label:'一键开店'
                },{
                    id:5,
                    label:'电商学院'
                },{
                    id:6,
                    label:'个人中心'
                }],
                defaultAvatar:require('@/assets/avatar.png')
            }
        },
         computed: {
            ...mapGetters([
                'sidebar',
                'user'
            ]),
            isCollapse() {
                return !this.sidebar.opened
            },
            classObj() {
                return {
                    hideSidebar: !this.sidebar.opened,
                    openSidebar: this.sidebar.opened
                }
            }
         },
         mounted() {
            document.addEventListener('click', this.hideAside);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.hideAside);
        },
        methods:{
            // 前往小程序
            toMiniProgram(){
                this.$dialog.confirm({
                    message: '点击前往小程序，解锁更多详情内容',
                    confirmButtonText:'点击跳转',
                    confirmButtonColor:'#3574F5',
                    cancelButtonText:'我再想想',
                    width:'234px'
                })
                .then(() => {
                    // on confirm
                    getSchemeUrl({
                        path:'/pages/index/index'
                    }).then(res=>{
                        location.href = res.data.link_url
                    })
                })
                .catch(() => {
                    // on cancel
                  
                });
            },
            toRegister() {
                this.$router.push(`/register?redirect=${this.$route.fullPath}`)
            },
            hideAside(event){
                if (!this.$el.contains(event.target) && event.target.className !== 'expand-img') {
                    this.$store.dispatch('app/closeSideBar')
                }
            }
        }
    }
</script>

<style scoped lang="less">
@import "~@/styles/var.less";
@sideBarWidth:289px;
.aside-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    background: #fff;
    z-index: 99;
    width: @sideBarWidth;
    transition: width 0.28s;
    overflow: hidden;
    &.hideSidebar {
        width:0 !important;
    }
    .aside-box {
        padding: 61px 17px 0 28px;
        box-sizing: border-box;
        height: 100%;
        .header {
            font-size: 12px;
            .avatar-box {
                img {
                    width: 45px;
                    height: 45px;
                    object-fit: cover;
                    border-radius: 50%;
                }
                .login {
                    font-weight: 500;
                    color: @primary;
                    margin-left: 13px;
                }
            }
            .register {
                font-weight: 400;
                color: #D8D8D8;
            }
        }
        .open-btn {
            width: 100%;
            height: 28px;
            line-height: 28px;
            margin-top: 18px;
            background: #EEF3FF;
            border-radius: 4px;
            border: 1px solid @primary;
            font-size: 12px;
            font-weight: 500;
            color: @primary;
            text-align: center;
        }
        .menu-item {
            padding: 20px 0;
            box-sizing: border-box;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            border-bottom: 1px solid #E4E4E4;
        }
    }
}
</style>