<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  mounted () {
    if (!this._isMobile()) {
      if (location.href.indexOf("newsDetail") != -1) {
        window.location.href = "https://chnakj.com" + location.pathname;
        return;
      }
      if (location.href.indexOf("register") != -1) {
        window.location.href = "https://chnakj.com/register";
        return;
      }
      window.location.href = "https://chnakj.com";
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
}
</script>
