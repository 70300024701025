import request from '@/utils/request'
//获取文章标签
export function getArticleTag() {
    return request({
        url: '/api/article/getTag',
        method: 'get'
    })
}
//获取文章树形分类
export function getArticleTreeCateList(){
    return request({
        url: '/api/article/getCategoryTree',
        method: 'get'
    })
}
//获取文章列表
export function getArticleList(params){
    return request({
        url: '/api/article/getList',
        method: 'get',
        params
    })
}
// 获取文章详情
export function getArticleDetail(params){
    return request({
        url: '/api/article/getDetail',
        method: 'get',
        params
    })
}
// 热门搜索词
export function getHostSearchList(params){
    return request({
        url: '/api/article/hotSearch',
        method: 'get',
        params
    })
}

