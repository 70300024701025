import request from '@/utils/request'
// 获取首页banner图
export function getBanner(params){
    return request({
        url: '/api/common/getBanner',
        method: 'get',
        params
    })
}

// 获取小程序链接
export function getSchemeUrl(data){
    return request({
        url: '/api/common/getUrlLink',
        method: 'post',
        data
    })
}