import axios from 'axios'
import { Toast } from 'vant';
import { getToken } from "@/utils/auth"
import router from '../router'


import store from '@/store'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    const token = getToken()
    if(token) {
      config.headers['Authorization'] = token
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  res => {
    if(res.headers.authentication) {
      // 响应头如果有authentication 存储到localstorage  之后请求都需要带上token
      localStorage.adminToken = res.headers.authentication
    }
    // return res.data
    const code = res.data.code
    const message = res.data.message
    // if the custom code is not 200, it is judged as an error.
    if (res.code === 401) {
      // to re-login
      
    }else if (code !== 200) {
      Toast.fail(message || 'Error');

      // 401: Token expired;
      
      return Promise.reject(new Error(message || 'Error'))
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
