import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/permission'
import store from './store'
import './styles/global.less'
import './styles/util.less'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { Swipe, SwipeItem,Empty,Dialog,Toast,Form,Field,Button } from 'vant';
import 'vant/lib/swipe/style';
import 'vant/lib/swipe-item/style';
import 'vant/lib/empty/style';
import 'vant/lib/dialog/style';
import 'vant/lib/toast/style';
import 'vant/lib/form/style';
import 'vant/lib/field/style';
import 'vant/lib/button/style';
Vue.component('Swiper', Swiper);
Vue.component('SwiperSlide', SwiperSlide)
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Empty);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
