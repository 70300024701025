<template>
  <div class="home-container">
    <Header @toggleClick="toggleSideBar" />
    <div class="carousel-container">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in bannerList" :key="index">
          <img :src="item.image" alt="" style="width: 100%; height: 100%;object-fit:cover;" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="service-box">
      <div class="func-container">
        <div class="func-header acea-row">
          <div class="title">综合服务</div>
          <div class="intro" @click.stop="toMiniProgram">入驻平台</div>
        </div>
        <div class="acea-row row-between">
          <div v-for="item in funcList" :key="item.id" class="func-item" @click.stop="toMiniProgram">
            <img class="item-img" :src="require(`@/assets/${item.type}.png`)" :alt="item.title">
            <div class="item-title">{{item.title}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="news-box">
      <div class="title">最新资讯</div>
      <div class="news-header">
        <div class="news-bigcate-box acea-row row-middle">
            <swiper :options="swiperOption">
              <swiper-slide v-for="(item) in bigCateList" :key="item.id" 
                  class="bigcate-item" 
                  :class="{'on':bigCate==item.id}"
                  @click.native="bigCateClick(item)">
                  <span>{{ item.label }}</span>
              </swiper-slide>
            </swiper>
        </div>
        <div class="news-subCate-box acea-row row-middle">
          <swiper :options="swiperOption">
            <swiper-slide v-for="(item,index) in secondCateList" :key="item.id" 
                class="subcate-item" 
                :class="{'on': index===subCateIndex}"
                @click.native="toSelectSubCate(item,index)">
                <span>{{ item.label }}</span>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="news-container">
        <template v-if="newsList&&newsList.length">
          <ul class="news-list">
            <li
              v-for="(item, index) in newsList"
              :key="item.id"
              class="news-item acea-row row-between"
              :class="{ active: index == 0 }"
              @click="toViewDetailNews(item.id)"
            >
              <img :src="item.cover" alt="">
              <div class="content">
                <div class="item-title">{{ item.title }}</div>
                <div class="des-box acea-row rpw-middle">
                  <div class="tag-item" v-for="(item,index) in item._tag" :key="index">{{ item }}</div>
                  <div class="tag-item">{{ item.updated_at }}</div>
                </div>
              </div>
            </li>
          </ul>
          <div class="more acea-row row-middle row-center" @click.stop="toMiniProgram">查看更多
            <img src="@/assets/arrow-right.png" alt="">
          </div>
        </template>
        <van-empty v-else description="没有更多资讯" />
      </div>
    </div>
    <Aside />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header"
import Aside from "@/components/Aside"
import { getBanner } from "@/api/index"
import { getArticleTreeCateList, getArticleList,getArticleTag } from "@/api/news";
import {formatDate} from '@/utils/formatDate.js'
import { getSchemeUrl } from "@/api/index"
export default {
  name: 'Home',
  components: {
    Header,
    Aside
  },
  data(){
    return {
      bannerList:[],
      funcList: [
        {
          id: 1,
          type: "tax",
          title: "工商税务",
          color: "#F79162",
          serviceId:'1',//对应综合服务分类树的id
        },
        {
          id: 2,
          type: "finance",
          title: "金融服务",
          color: "#46976F",
          serviceId:'9'
        },
        {
          id: 3,
          type: "logistics",
          title: "跨境物流",
          color: "#4A83E7",
          serviceId:'18'
        },
        {
          id: 4,
          type: "law",
          title: "法律服务",
          color: "#7171F9",
          serviceId:'24'
        },
        {
          id: 5,
          type: "knowledge",
          title: "知识产权",
          color: "#7171F9",
          serviceId:'23'
        },
        {
          id: 6,
          type: "tool",
          title: "运营工具",
          color: "#4A83E7",
          serviceId:'25'
        },
        {
          id: 7,
          type: "resource",
          title: "服务商资源",
          color: "#46976F",
        },
        {
          id: 8,
          type: "network",
          title: "跨境网络安全",
          color: "#F79162"
        },
      ],
      swiperOption:{
        autoplay: false,
        spaceBetween:20,
        slidesPerView: 'auto',
      },
      bigCate: "", //一级分类
      bigCateList: [],
      secondCateList: [],
      subCateIndex:0,//二级分类选中索引
      newsList: [],
      tagList:[],
    }
  },
  async created() {
    this.getBanner()
    await this.getArticleTreeCateList();
    if (
      this.bigCateList.length &&
      this.bigCateList[0].children &&
      this.bigCateList[0].children.length
    ) {
      this.secondCateList = this.bigCateList[0].children;
    }
    let category_id = ''
    if(this.secondCateList.length){
      category_id = this.secondCateList[0].id
    }
    const { data } = await getArticleTag()
    this.tagList = data
    this.getArticleList(category_id);
  },
  methods:{
    // 前往小程序
    toMiniProgram(){
      this.$dialog.confirm({
        message: '点击前往小程序，解锁更多详情内容',
        confirmButtonText:'点击跳转',
        confirmButtonColor:'#3574F5',
        cancelButtonText:'我再想想',
        width:'234px'
      })
      .then(() => {
        // on confirm
        getSchemeUrl({
          path:'/pages/index/index'
        }).then(res=>{
          location.href = res.data.link_url
        })
      })
      .catch(() => {
        // on cancel
        
      });
    },
    toViewDetailNews(id) {
      this.$router.push(`/newsDetail/${id}`)
    },
    toSelectSubCate(item,index){
      this.subCateIndex = index
      this.getArticleList(item.id);
    },
    getTagList(tag){
      let arr = []
      if(tag&&tag.length) {
        for (let i = 0; i < tag.length; i++) {
          const temp = this.tagList.find(item=>item.key == tag[i])
          arr.push(temp ? temp.title : '')
        }
      }
      return arr
    },
    getArticleList(category_id, tag) {
      getArticleList({ category_id, tag }).then((res) => {
        if (res.data && res.data.data.length) {
          const data = res.data.data
          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            this.$set(item,'_tag',this.getTagList(item.tag))
            item.updated_at = formatDate(item.updated_at,false,false)
          }
          this.newsList = res.data.data
        }else {
          this.newsList = []
        }
      });
    },
    bigCateClick(tab) {
      this.subCateIndex = 0
      this.bigCate = tab.id
      const temp = this.bigCateList.find((item) => item.id == tab.id);
      if (temp) {
        this.secondCateList = temp.children;
        const cateId = temp.children&&temp.children.length?temp.children[0].id : tab.id
        // 获取资讯
        this.getArticleList(cateId)
      }
    },
    async getArticleTreeCateList() {
      await getArticleTreeCateList().then((res) => {
        if (res.data && res.data.length) {
          this.bigCateList = res.data;
          this.bigCate = res.data[0].id.toString();
        }
      });
    },
    getBanner(){
      getBanner({
        type:1
      }).then(res=>{
        this.bannerList = res.data
      })
    },
    toggleSideBar(){
      this.$store.dispatch('app/toggleSideBar')
    }
  }
}
</script>
<style lang="less" scoped>
@import "~@/styles/var.less";
@import "~@/styles/mixin.less";
.home-container {
  position: relative;
  background: #F5F6F7;
  height: 100%;
  .carousel-container {
    width: 375px;
    height: 180px;
    .my-swipe {
      height: 100%;
    }
  }
  .service-box {
    padding-bottom: 20px;
    .func-container {
      padding: 20px 16px 0;
      background: #fff;
      box-sizing: border-box;
      .func-header {
        margin-bottom: 21px;
        .title {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          margin-right: 20px;
        }
        .intro{
          width: 66px;
          height: 22px;
          background: @primary;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 22px;
          text-align: center;
        }
      }
      .func-item {
        width: 25%;
        margin-bottom: 16px;
        text-align: center;
        .item-img {
          width: 26px;
          height: 30px;
        }
        .item-title {
          min-width: 58px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
        }
      }
    }
  }
  .news-box {
    background: #fff;
    .title {
      display: flex;
      padding: 20px 0 0 17px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    .news-header{
      /deep/.swiper-container{
        margin-left: 16px;
        flex: 1;
      }
      .news-bigcate-box {
        width: 100%;
        margin-top: 16px;
        border-bottom: 1px solid #E4E4E4;
        box-sizing: border-box;
        .bigcate-item {
          width: auto;
          min-width: 58px;
          font-size: 14px;
          font-weight: 400;
          color: #A7A7A7;
          box-sizing: border-box;
          &.on{
            color: #333333;
            font-weight: 500;
            ::after {
              display: block;
              content: '';
              width: 17px;
              border-bottom: 2px solid @primary;
              margin: 5px auto 0;
            }
          }
        }
      }
      .news-subCate-box {
        margin: 15px auto;
        /deep/.swiper-container{
          margin-left: 17px;
        }
        .subcate-item {
          width: auto;
          min-width: 73px;
          height: 22px;
          background: #F2F3F4;;
          border-radius: 4px;
          padding: 2px 12px;
          box-sizing: border-box;
          font-size: 12px;
          font-weight: 400;
          color: @blackWords;
          text-align: center;
          &.on{
            color: #fff;
            background: @primary;
          }
        }
      }
    }
    .news-container {
      padding-bottom: 30px;
      .news-list {
        padding:0 16px;
        .news-item {
          color: #a7a7a7;
          box-sizing: border-box;
          margin-bottom: 20px;
          flex: 1;
          img {
            width: 120px;
            height: 80px;
            border-radius: 3px;
            object-fit: cover;
          }
          .content {
            position: relative;
            margin-left: 16px;
            flex:1;
            .item-title {
              width: 206px;
              font-size: 14px;
              font-weight: 500;
              .line-clamp(2);
              color:@blackWords;
            }
            .des-box {
              margin-top: 23px;
              position: absolute;
              bottom: 0;
              .tag-item {
                font-size: 12px;
                font-weight: 400;
                color: #A7A7A7;
                margin-right: 20px;
              }
            }
          }
        }
      }
      .more {
        width: 100px;
        height: 33px;
        background: #FFFFFF;
        border-radius: 21px;
        border: 1px solid @primary;
        font-size: 12px;
        color: @primary;
        margin: 0 auto;
        img {
          width: 11px;
          height: 16px;
          margin-left: 5px;
        }
      }
    }
  }
}

</style>