<template>
    <div class="header-container acea-row row-middle row-between">
        <div class="logo">
            <img @click="navBack" v-if="$route.path.indexOf('newsDetail') != -1" src="@/assets/back-btn.png" class="back-btn" alt="">
            <img src="@/assets/logo.png" alt="" class="logo-img">
        </div>
        <div class="expand">
            <img class="expand-img" src="@/assets/expand.png" @click="toggleClick">
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        methods:{
            toggleClick(){
                this.$emit('toggleClick')
            },
            navBack() {
              this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="less">
.header-container {
    height: 58px;
    width: 100%;
    padding: 0 16px 0 12px;
    box-sizing: border-box;
    background: #fff;
    .logo {
      display: flex;
      align-items: center;
    }
    .logo-img {
        width: 161px;
        object-fit: contain;
    }
    .back-btn {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
    .expand img {
        width: 24px;
        height: 18px;
    }
}
</style>